// divider
html {
  --mat-divider-color: theme('colors.divider');
}

// sidenav
html {
  --mat-sidenav-container-background-color: theme('colors.surface.container');
  --mat-sidenav-container-text-color: theme('colors.on.surface');
  --mat-sidenav-content-background-color: theme('colors.surface.container');
  --mat-sidenav-content-text-color: theme('colors.on.surface');
  --mat-sidenav-scrim-color: rgba(51, 47, 55, 0.4);
  --mat-sidenav-container-shape: 16px;
  --mat-sidenav-container-elevation-shadow: none;
  --mat-sidenav-container-width: theme('width.80');
  --mat-sidenav-container-divider-color: transparent;
}


// progress-bar
.mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: theme('colors.primary.800');
  --mdc-linear-progress-track-color: theme('colors.primary.200');
}

// dialog box
.mat-mdc-dialog-surface {
  --mdc-dialog-container-shape: theme('borderRadius.xl');
  --mdc-dialog-container-color: theme('colors.white');
}


// select
.mat-mdc-select-panel {
  --mat-select-panel-background-color: theme('colors.white');

  @apply !relative top-1 !rounded-md;
}

// checkbox (pseudo)
.mat-pseudo-checkbox {
  @disabled: theme('colors.gray.500');
  @selected: theme('colors.primary.700');
  @white: theme('colors.white');
  @border: theme('colors.gray.500');

  --mat-full-pseudo-checkbox-selected-icon-color: @selected;
  --mat-full-pseudo-checkbox-selected-checkmark-color: @white;
  --mat-full-pseudo-checkbox-unselected-icon-color: @border;
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: @white;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: @disabled;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: @disabled;

  --mat-minimal-pseudo-checkbox-selected-checkmark-color: @selected;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: @disabled;
}

// slider
.mat-mdc-slider  {
  @primary: theme('colors.primary.800');
  @primary-hover: theme('colors.primary.800/0.05');
  @primary-focused: theme('colors.primary.800/0.20');

  --mdc-slider-handle-color: @primary;
  --mdc-slider-focus-handle-color: @primary;
  --mdc-slider-hover-handle-color: @primary;
  --mdc-slider-active-track-color: @primary;
  --mdc-slider-inactive-track-color: theme('colors.gray.500');
  --mdc-slider-with-tick-marks-inactive-container-color: #49454e;
  --mdc-slider-with-tick-marks-active-container-color: #ffffff;
  --mdc-slider-disabled-active-track-color: #1d1b1e;
  --mdc-slider-disabled-handle-color: #1d1b1e;
  --mdc-slider-disabled-inactive-track-color: #1d1b1e;
  --mdc-slider-label-container-color: @primary;
  --mdc-slider-label-label-text-color: #ffffff;
  --mdc-slider-with-overlap-handle-outline-color: #ffffff;
  --mdc-slider-with-tick-marks-disabled-container-color: #1d1b1e;
  --mdc-slider-handle-elevation: 1;
  --mdc-slider-handle-shadow-color: #000000;
  --mdc-slider-label-label-text-font: theme('fontFamily.sans');
  --mdc-slider-label-label-text-size: 0.75rem;
  --mdc-slider-label-label-text-line-height: 1rem;
  --mdc-slider-label-label-text-tracking: 0.031rem;
  --mdc-slider-label-label-text-weight: 500;
  --mdc-slider-active-track-height: 4px;
  --mdc-slider-active-track-shape: 9999px;
  --mdc-slider-handle-height: theme('height.4');
  --mdc-slider-handle-shape: 9999px;
  --mdc-slider-handle-width: theme('width.4');
  --mdc-slider-inactive-track-height: 4px;
  --mdc-slider-inactive-track-shape: 9999px;
  --mdc-slider-with-overlap-handle-outline-width: 1px;
  --mdc-slider-with-tick-marks-active-container-opacity: 0.38;
  --mdc-slider-with-tick-marks-container-shape: 9999px;
  --mdc-slider-with-tick-marks-container-size: 2px;
  --mdc-slider-with-tick-marks-inactive-container-opacity: 0.38;
  --mat-slider-ripple-color: @primary;
  --mat-slider-hover-state-layer-color: @primary-hover;// rgba(125, 0, 250, 0.05);
  --mat-slider-focus-state-layer-color: @primary-focused; //rgba(125, 0, 250, 0.2);
  --mat-slider-value-indicator-width: 28px;
  --mat-slider-value-indicator-height: 28px;
  --mat-slider-value-indicator-caret-display: none;
  --mat-slider-value-indicator-border-radius: 50% 50% 50% 0;
  --mat-slider-value-indicator-padding: 0;
  --mat-slider-value-indicator-text-transform: rotate(45deg);
  --mat-slider-value-indicator-container-transform: translateX(-50%) rotate(-45deg);
  --mat-slider-value-indicator-opacity: 1;

  .mat-mdc-slider-visual-thumb {
    .mat-ripple {
      .mat-ripple-element {
        @apply !h-8 !w-8 !top-0 !left-2;
      }
    }
  } 

  &, .mdc-slider__input, .mdc-slider__thumb {
    @apply !h-8;
  }
}


// tabs 

.mat-mdc-tab-group.no-header {
  .mat-mdc-tab-header {
    @apply hidden;
  }
}

.mat-mdc-tab {
  & &-ripple {
    @apply absolute;

  }

  &-header {
    @primary: theme('colors.primary.800');

    --mdc-secondary-navigation-tab-container-height: theme('height.10');
    
    --mdc-tab-indicator-active-indicator-color: @primary;
    --mdc-tab-indicator-active-indicator-height: 2px;
    --mdc-tab-indicator-active-indicator-shape: 0px;
  
    --mat-tab-header-pagination-icon-color: #1d1b1e;
  
    --mat-tab-header-active-label-text-color: #1d1b1e;
    --mat-tab-header-active-ripple-color: #1d1b1e;
    --mat-tab-header-active-focus-label-text-color: @primary;
    --mat-tab-header-active-hover-label-text-color: @primary;
    --mat-tab-header-active-focus-indicator-color: @primary;
    --mat-tab-header-active-hover-indicator-color: @primary;
  
    --mat-tab-header-inactive-label-text-color: #1d1b1e;
    --mat-tab-header-inactive-ripple-color: #1d1b1e;
    --mat-tab-header-inactive-focus-label-text-color: @primary;
    --mat-tab-header-inactive-hover-label-text-color: @primary;
    
  
    --mat-tab-header-label-text-font: theme('fontFamily.sans');
    --mat-tab-header-label-text-size: theme('fontSize.base[0]');
    --mat-tab-header-label-text-tracking: 0.006rem;
    --mat-tab-header-label-text-line-height: theme('lineHeight.normal');
    --mat-tab-header-label-text-weight: 500;
  
    --mat-tab-header-divider-color: #e8e0eb;
    --mat-tab-header-divider-height: 1px;
  }
}
