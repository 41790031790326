@import './tailwind.less';
@import './material.overrides.less';
@import './buttons.less';
@import './snackbar.less';
@import './forms.less';
@import './tables.less';
@import './swiper.less';

@layer base {
  body {
    min-width: theme('containers.sm');
    background-color: theme('colors.surface.container');
    color: theme('colors.on.surface')
  }

  pre {
    @apply font-sans text-balance;
    overflow-wrap: break-word;
  }

  a {
    @apply outline-none;
  }
}

@layer components {
  .page {
    @apply block p-4 md:p-8 container transition-all;

    &.full {
      @apply max-w-none;
    }
  }

  .demo-placeholder {
    @apply rounded bg-gray-500/50 animate-pulse p-4;
  }
}

fa-icon {
  @apply flex place-content-center w-5;

  > svg.svg-inline--fa {
    @apply w-full h-full aspect-square;
  }
}

img:where([icon]) {
  @apply aspect-square w-6;
}

swiper-container {
  @apply max-w-full;
}

.fa-whatsapp {
  transform: scale(calc(64 / 56));
}
