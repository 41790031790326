@layer base {
  input {
    @apply outline-none;
  }
  
  textarea {
    @apply block bg-transparent w-full h-full outline-none placeholder:text-sm;

    &::-webkit-scrollbar {
      @apply absolute h-1 w-1 rounded-full;
    }

    &::-webkit-scrollbar-thumb {
      @apply rounded bg-black;
    }

    &::-webkit-scrollbar-track {
      @apply rounded bg-divider;
    }

    &::-webkit-scrollbar,
    &::-webkit-scrollbar-corner {
      @apply bg-divider;
    }
  }
}

@layer components {
  .field {
    @apply w-full rounded-md p-2 text-sm leading-none
    placeholder:text-gray-500/80 placeholder:text-sm 
    ring-1 ring-divider shadow-sm shadow-divider
    outline-none transition-all bg-surface;

    &-highlight {
      @apply ring-primary-800;
    }

    &-active {
      @apply field-highlight ring-2;
    }

    &:where(:hover) {
      @apply field-highlight;
    }

    &:where(:focus), &:where(:focus-within) {
      @apply field-active;
    }
  }

  .hint {
    @apply text-xs mt-1 px-2 text-gray-500;
  }

  .error {
    @apply text-xs mt-1 px-2 text-red-500;
  }

  .form-field {
    @apply relative mb-8;

    > label {
      @apply block mb-1 text-sm font-semibold;
    }

    > .hint {
      @apply absolute block;
    }

    > .error {
      @apply absolute hidden text-warn-500 transition-all;
    }

    .mat-mdc-select-value {
      @apply transition-all;
    }

    .field-spinner {
      @apply absolute hidden w-6;
    }

    &.show-spinner {
      input,
      .mat-mdc-select-value {
        @apply pr-8;
      }

      .field-spinner {
        @apply block;
      }
    }

    &.disabled {
      > label {
        @apply text-gray-500;
      }
    }
  }

  form.ng-submitted .form-field,
  form.ng-dirty .form-field.dirty,
  td.form-field.dirty {
    &.invalid {
      label {
        @apply text-warn-500;
      }

      .field {
        @apply ring-warn-500/80 placeholder-warn-500;
      }

      > .error {
        @apply block;

        &:not(:first-of-type) {
          @apply hidden;
        }

        & ~ .hint {
          @apply hidden;
        }
      }
    }
  }
}

input:where([type=text]),
.mat-mdc-select-trigger {
  @apply field;
}
 
.mat-mdc-select[aria-expanded=true] {
  .mat-mdc-select-trigger {
    @apply field-active;
  }
}