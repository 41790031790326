/* You can add global styles to this file, and also import other style files */
@import '../../../libs/shared/styles/src/global.less';

@layer components {
  .page-section {
    > .header {
      @apply p-1 px-4 rounded-2xl bg-primary-container text-on-primary-container font-bold;
    }

    > .content {
      @apply p-2 rounded-2xl mt-2;
    }
  }
}
