@layer components {
  button {
    @apply outline-none;
  }

  .filled-button {
    --source-color: theme('colors.primary.900');

    @apply relative p-1 px-4 rounded-md font-normal transition-all w-max;
    
    @apply text-on-primary text-sm;
    background-color: var(--source-color) ;

    &:hover, &:focus {
      background-color: ~"hsl(from var(--source-color) h calc(s - 7) calc(l + 7))";
    }

    &:disabled, &.disabled {
      @apply text-gray-400 bg-gray-100;
    }
  }

  .tonal-button {
    --source-color: theme('colors.primary.900');

    @apply p-1 px-4 rounded-md font-normal transition-all w-max;
    @apply text-primary text-sm;
    background-color: hsl(from var(--source-color) h 50 90);

    &:hover, &:focus {
      background-color: hsl(from var(--source-color) h 50 85);
    }

    &:disabled, &.disabled {
      @apply text-gray-400 bg-gray-100;
    }
  }

  .outlined-button {
    --source-color: transparent;
    @apply relative block rounded-md py-1 px-4 text-center text-sm
      transition-all;

    @apply border border-divider after:absolute;

    &::after {
      @apply pointer-events-none transition-all inset-0 rounded-md
        mix-blend-overlay
        opacity-0;
      background-color: theme('colors.black / 0.075');
    }

    &:hover, &:focus {
      &::after {
        @apply opacity-100;
      }
    }

    &:disabled, &.disabled {
      @apply text-gray-400 bg-gray-200 border-gray-200;

      &::before, &::after {
        @apply hidden;
      }
    }
  }

  .text-button {
    --source-color: transparent;
    @apply relative block rounded-md py-1 px-4 text-center text-sm
      transition-all;

    @apply after:absolute;

    &::after {
      @apply pointer-events-none transition-all inset-0 rounded-md
        mix-blend-overlay
        opacity-0;
      background-color: theme('colors.black / 0.075');
    }

    &:hover, &:focus {
      &::after {
        @apply opacity-100;
      }
    }

    &:disabled, &.disabled {
      @apply text-gray-400 bg-gray-200;

      &::before, &::after {
        @apply hidden;
      }
    }
  }

  
  .icon-button {
    --source-color: transparent;
    @apply relative grid place-content-center rounded-full
      w-10 h-10 text-lg
      transition-all;

    @apply after:absolute;

    &::after {
      @apply pointer-events-none transition-all inset-0 rounded-full
        mix-blend-overlay
        opacity-0;
      background-color: theme('colors.black / 0.075');
    }
    
    fa-icon {
      @apply leading-none;
    }
    
    &.small {
      @apply h-6 w-6;

      fa-icon {
        @apply h-4 w-4;
      }
    }

    &.large {
      @apply h-12 w-12 text-2xl;
    }

    &:hover, &:focus {
      &::after {
        @apply opacity-100;
      }
    }

    &.primary {
      @apply bg-primary-500 text-white;

      &:where(:hover), &:where(:focus) {
        @apply bg-primary-600;
      }
    }
      
    &:disabled, &.disabled {
      @apply text-gray-400 bg-gray-200;

      &::before, &::after {
        @apply hidden;
      }
    }

  }

  // .shaded-button {
  //   @apply bg-white rounded-md p-1 px-4 font-medium transition-all w-max;

  //   &:hover,
  //   &:focus {
  //     @apply bg-gray-100;
  //   }

  //   &:where(.primary) {
  //     @apply bg-primary-500 text-white;

  //     &:hover,
  //     &:focus {
  //       @apply bg-primary-600;
  //     }
  //   }

  //   &:disabled,
  //   &.disabled {
  //     @apply text-gray-400 bg-gray-100;
  //   }
  // }

  // .stroked-button {
  //   @apply block rounded-md font-medium transition-all w-max;

  //   @apply bg-white border border-divider;

  //   padding-block: calc(theme('padding.1') - theme('borderWidth.DEFAULT'));
  //   padding-inline: calc(theme('padding.4') - theme('borderWidth.DEFAULT'));

  //   &:where(.primary) {
  //     @apply text-primary-500;
  //   }

  //   &:where(.warn) {
  //     @apply text-warn-500;
  //   }

  //   &:hover,
  //   &:focus {
  //     @apply bg-gray-100;
  //   }

  //   &:disabled,
  //   &.disabled {
  //     @apply text-gray-400 bg-gray-100 border-gray-100;
  //   }
  // }

  // .icon-button {
  //   @apply grid place-content-center rounded-full
  //     w-10 h-10
  //     transition-all;

  //   fa-icon {
  //     @apply leading-none h-4;
  //   }

  //   &.small {
  //     @apply h-6 w-6 text-sm;
  //   }

  //   &.large {
  //     @apply h-12 w-12 text-2xl;
  //   }

  //   &:where(:hover),
  //   &:where(:focus) {
  //     @apply bg-gray-500/10;
  //   }

  //   &:disabled {
  //     @apply bg-transparent text-gray-500/50 transition-none;
  //   }
  // }
}
