.swiper {
  &-pagination {
    @apply relative mt-2;
    --swiper-pagination-top: auto;
    --swiper-pagination-bottom: auto;

    &-bullet {
      @apply bg-primary-300 transition-all;

      &-active {
        @apply w-4 rounded bg-primary-500;
      }
    }
  }

  &-button {
    &-prev, &-next {
      @apply static mt-0 rounded-full box-border;
      padding: 0.5rem;

      stroke-width: 3rem;

      &::after {
        @apply hidden;
      }
    }
  }
}
