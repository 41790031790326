@use '@angular/material' as mat;

$light-theme: mat.define-theme();

$theme: $light-theme;

html {
  @include mat.core();
  @include mat.core-theme($theme);
  @include mat.progress-bar-theme($theme);
  @include mat.divider-theme($theme);
  @include mat.snack-bar-theme($theme);
  @include mat.sidenav-theme($theme);
  @include mat.dialog-theme($theme);
  @include mat.tooltip-theme($theme);
  @include mat.select-theme($theme);
  @include mat.slider-theme($theme);
  @include mat.tabs-theme($theme);
}
