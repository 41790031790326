.table-container {
  @apply border rounded;

  table {
    @apply w-full;
  }

  td, th {
    @apply p-2 px-4 text-left;
  }

  tr {
    @apply border-b;
  }

  tbody {
    tr:last-child {
      @apply border-b-0;
    }
  }
}