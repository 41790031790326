@layer components {
  mat-snack-bar-container {
    &.success-snackbar:not(.with-actions) {
      .mdc-snackbar__surface {
        @apply bg-green-400;
      }
      .mdc-snackbar__label {
        @apply text-black;
      }
    }

    &.error-snackbar {
      .mdc-snackbar__surface {
        @apply bg-red-400;
      }
      .mdc-snackbar__label {
        @apply text-black;
      }
    }

    &.warn-snackbar {
      .mdc-snackbar__surface {
        @apply bg-amber-300;
      }
      .mdc-snackbar__label {
        @apply text-black;
      }
    }
  }
}
